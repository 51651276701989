import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import {Subject, Subscription} from 'rxjs';
import {IAppointment} from './appointment.model';
import * as UI from '../shared/ui.actions';
import {map} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {IUser} from './user.model';
import {AuthService} from '../auth/auth.service';
import moment from 'moment';
import {UserMessageService} from './user-message/user-message.service';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable()
export class AppointmentService {
  userData: IUser;
  createMessageSubject = new Subject<boolean>();
  appointment: IAppointment;
  allAppointments: IAppointment[] = [];
  statusType = new Subject<string>();
  appointmentSubject = new Subject<IAppointment>();
  appointmentsSubject = new Subject<IAppointment[]>();
  approvedAppointmentsSubject = new Subject<IAppointment[]>();
  private fbSubs: Subscription[] = [];

  constructor(private store: Store<{ui: fromRoot.State}>,
              private auth: AuthService,
              private afAuth: AngularFireAuth,
              private _surgeryMessageService: UserMessageService,
              private afs: AngularFirestore) {
  }

  getAppointments() {
    this.store.dispatch(new UI.StartLoading());
    this.fbSubs.push(
      this.auth.user$.subscribe(user => {
        this.userData = user;
        this.fbSubs.push(
          this.afs
            .collectionGroup<IAppointment>('appointments',
              ref => ref.orderBy('requestDate', 'desc'))
            .snapshotChanges()
            .pipe(
              map(actions => {
                return actions.map(action => {
                  const data = action.payload.doc.data() as IAppointment;
                  return {
                    appointmentId: action.payload.doc.id,
                    appointmentDate: data.appointmentDate,
                    appointmentInformation: data.appointmentInformation,
                    appointmentResponse: data.appointmentResponse,
                    status: data.status,
                    cancelReason: data.cancelReason,
                    appointmentType: data.appointmentType,
                    chosenDoctor: data.chosenDoctor,
                    declineReason: data.declineReason,
                    // possibleTimes: data.possibleTimes,
                    requestDate: data.requestDate,
                    userData: data.userData,
                    adminStatus: data.adminStatus,
                    patientStatus: data.patientStatus,
                  };
                });
              })
            )
            .subscribe((appointments: IAppointment[]) => {
              this.store.dispatch(new UI.StopLoading());
              this.allAppointments = appointments;
              this.appointmentsSubject.next([...this.allAppointments]);
              // Now using Reducer
              // this.store.dispatch(new Doctor.SetDoctors(doctors))
            }));
      }));
  }

  getAppointment(id: string, status: string) {
    this.store.dispatch(new UI.StartLoading());
    this.fbSubs.push(
        this.afs
          .doc<IAppointment>(`appointments/${status}/appointments/${id}`)
        .snapshotChanges()
        .pipe(
          map(action => {
            const data = action.payload.data() as IAppointment;
            return {
              appointmentId: action.payload.id,
              appointmentDate: data.appointmentDate,
              appointmentInformation: data.appointmentInformation,
              appointmentResponse: data.appointmentResponse,
              declineReason: data.declineReason,
              cancelReason: data.cancelReason,
              status: data.status,
              chosenDoctor: data.chosenDoctor,
              appointmentType: data.appointmentType,
              // possibleTimes: data.possibleTimes,
              requestDate: data.requestDate,
              adminStatus: data.adminStatus,
              patientStatus: data.patientStatus,
              userData: data.userData,
            };
          })
        )
        .subscribe((appointment: IAppointment) => {
          this.store.dispatch(new UI.StopLoading());
          this.appointment = appointment;
          this.appointmentSubject.next(this.appointment);
        }));
  }

    // I refactored the below to be a promise to avoid using a subject

  makeAppointment(appointmentData: IAppointment) {
    const makeAppointment = {
      appointmentDate: moment(appointmentData.appointmentDate).toDate(),
      status: appointmentData.status,
      // possibleTimes: appointmentData.possibleTimes,
      chosenDoctor: appointmentData.chosenDoctor,
      appointmentInformation: appointmentData.appointmentInformation,
      appointmentType: appointmentData.appointmentType,
      requestDate: appointmentData.requestDate,
      adminStatus: 'Pending',
      patientStatus: 'Pending',
      userData: {
        userId: this.userData.userId,
        name: this.userData.name,
        surname: this.userData.surname,
        phone: this.userData.phone,
        doctor: this.userData.doctor,
        email: this.userData.email,
        birthday: this.userData.birthday.toDate()
      }
    };
    this.afs
      .collection('appointments')
      .doc(`${appointmentData.status}`)
      .collection('appointments')
      .add(makeAppointment)
      .catch()
      .then((docRef) => {
        this.statusType.next('Make');
        const messageData = {
          name: makeAppointment.userData.name,
          surname: makeAppointment.userData.surname,
          phone: makeAppointment.userData.phone,
          email: makeAppointment.userData.email,
          emailDate: new Date(),
          messageTitle: `Appointment Request from ${makeAppointment.userData.name + ' ' + makeAppointment.userData.surname}`,
          messageType: 'alerts',
          patientMessage: makeAppointment.appointmentInformation,
          cancelReason: null,
          adminResponse: null,
          adminStatus: 'Pending Appointment',
          messageSubject: `${makeAppointment.appointmentType} Appointment`,
          status: 'Pending',
          sendTo: 'contact@queenstreetsurgery.co.za',
          patient: true,
          refDoc: docRef.id
        };
        this._surgeryMessageService.sendMessage(messageData);
      });
  }

  approveAppointment(userData: IAppointment, appointmentData: IAppointment) {
    const approvedAppointment = {
      status: appointmentData.status,
      chosenDoctor: userData.chosenDoctor,
      // possibleTimes: appointmentData.possibleTimes,
      appointmentResponse: appointmentData.appointmentResponse,
      appointmentInformation: userData.appointmentInformation,
      appointmentType: userData.appointmentType,
      appointmentDate: userData.appointmentDate,
      requestDate: userData.requestDate,
      appointmentId: userData.appointmentId,
      userData: userData.userData
    };
    this.afs
      .collection('appointments')
      // .doc(`${data.userData.userId}`)
      .doc('Pending')
      .collection('appointments')
      .doc<IAppointment>(`${userData.appointmentId}`)
      .delete()
      .catch()
      .then((docRef) => {
        this.createMessageSubject.next(true);
        const approveData = {
          appointmentDate: approvedAppointment.appointmentDate,
          appointmentInformation: approvedAppointment.appointmentInformation,
          appointmentResponse: approvedAppointment.appointmentResponse,
          appointmentType: approvedAppointment.appointmentType,
          chosenDoctor: approvedAppointment.chosenDoctor,
          // possibleTimes: approvedAppointment.possibleTimes,
          requestDate: approvedAppointment.requestDate,
          status: 'Approved',
          adminStatus: 'Approved',
          patientStatus: 'Approved',
          userData: approvedAppointment.userData,
        };
        const approveMessage = {
          name: approvedAppointment.userData.name,
          surname: approvedAppointment.userData.surname,
          phone: approvedAppointment.userData.phone,
          email: 'contact@queenstreetsurgery.co.za',
          emailDate: new Date(),
          messageTitle: 'Appointment Request Approved',
          messageType: 'alerts',
          patientMessage: approvedAppointment.appointmentInformation,
          adminResponse: approvedAppointment.appointmentResponse,
          adminStatus: 'Approved Appointment',
          messageSubject: `${approvedAppointment.appointmentType} Appointment Approved`,
          status: 'Approve',
          sendTo: approvedAppointment.userData.email,
          patient: false,
          refDoc: approvedAppointment.appointmentId,
        };

        this.afs
          .collection('appointments')
          .doc('Approved')
          .collection('appointments')
          .add(approveData)
          .then(() => {
            this._surgeryMessageService.sendMessage(approveMessage);
          });
      });
  }


  declineAppointment(declinedAppointment: IAppointment) {
    this.afs
      .collection('appointments')
      // .doc(`${data.userData.userId}`)
      .doc('Pending')
      .collection('appointments')
      .doc<IAppointment>(`${declinedAppointment.appointmentId}`)
      .delete()
      .catch()
      .then((docRef) => {
        this.createMessageSubject.next(true);
        const declineData = {
          appointmentDate: declinedAppointment.appointmentDate,
          appointmentInformation: declinedAppointment.appointmentInformation,
          appointmentResponse: declinedAppointment.declineReason,
          appointmentType: declinedAppointment.appointmentType,
          chosenDoctor: declinedAppointment.chosenDoctor,
          // possibleTimes: declinedAppointment.possibleTimes,
          requestDate: declinedAppointment.requestDate,
          adminStatus: 'Declined',
          patientStatus: 'Declined',
          status: 'Declined',
          userData: declinedAppointment.userData,
        };
        const declineMessage = {
          name: declinedAppointment.userData.name,
          surname: declinedAppointment.userData.surname,
          phone: declinedAppointment.userData.phone,
          email: 'contact@queenstreetsurgery.co.za',
          emailDate: new Date(),
          messageType: 'alerts',
          messageTitle: 'Appointment Request Declined',
          patientMessage: declinedAppointment.appointmentInformation ? declinedAppointment.appointmentInformation : null,
          cancelReason: declinedAppointment.cancelReason ? declinedAppointment.cancelReason : null,
          adminResponse: declinedAppointment.declineReason ? declinedAppointment.declineReason : null,
          adminStatus: 'Declined Appointment',
          messageSubject: `${declinedAppointment.appointmentType} Appointment Declined`,
          status: 'Declined',
          sendTo: declinedAppointment.userData.email,
          refDoc: declinedAppointment.appointmentId,
          patient: false,
        };
        this.afs
          .collection('appointments')
          .doc('Declined')
          .collection('appointments')
          .add(declineData)
          .then(() => {
            this._surgeryMessageService.sendMessage(declineMessage);
          });
      });
  }

  cancelAppointment(submitCanceledAppointmentData) {

    const canceledAppointmentData = {
      appointmentDate: submitCanceledAppointmentData.appointmentDate,
      appointmentInformation: submitCanceledAppointmentData.appointmentInformation,
      appointmentResponse: submitCanceledAppointmentData.appointmentResponse,
      requestDate: submitCanceledAppointmentData.requestDate,
      // possibleTimes: submitCanceledAppointmentData.possibleTimes,
      appointmentId: submitCanceledAppointmentData.appointmentId,
      chosenDoctor: submitCanceledAppointmentData.chosenDoctor,
      appointmentType: submitCanceledAppointmentData.appointmentType,
      status: 'Cancelled',
      userData: submitCanceledAppointmentData.userData,
      cancelReason: submitCanceledAppointmentData.cancelReason,
      adminStatus: submitCanceledAppointmentData.adminStatus,
      patientStatus: 'Cancelled'
    };
    return this.afs
      .collection('appointments')
      .doc(`${submitCanceledAppointmentData.status}`)
      .collection('appointments')
      .doc<IAppointment>(`${submitCanceledAppointmentData.appointmentId}`)
      .delete()
      .catch()
      .then(() => {
        this.statusType.next('Cancel');
        this.afs
          .collection('appointments')
          .doc('Cancelled')
          .collection('appointments')
          .add(canceledAppointmentData)
          .then(() => {
            const cancelMessage = {
              name: submitCanceledAppointmentData.userData.name,
              surname: submitCanceledAppointmentData.userData.surname,
              phone: submitCanceledAppointmentData.userData.phone,
              email: submitCanceledAppointmentData.userData.email,
              emailDate: new Date(),
              messageType: 'alerts',
              messageTitle: 'Appointment Request Cancelled',
              patientMessage: canceledAppointmentData.appointmentInformation,
              cancelReason: canceledAppointmentData.cancelReason,
              adminResponse: canceledAppointmentData.appointmentResponse,
              patientStatus: 'Cancelled Appointment',
              adminStatus: 'Cancelled Appointment',
              messageSubject: `${submitCanceledAppointmentData.appointmentType} Appointment Cancelled`,
              status: 'Cancelled',
              sendTo: 'contact@queenstreetsurgery.co.za',
              refDoc: submitCanceledAppointmentData.appointmentId,
              patient: true,
            };
            this._surgeryMessageService.sendMessage(cancelMessage);
            this.appointmentsSubject.next([...this.allAppointments]);
          });
      });
  }

  deleteAppointment(appointment: IAppointment): Promise<void> {
    const deleteAppointmentData = {
      appointmentDate: appointment.appointmentDate,
      appointmentInformation: appointment.appointmentInformation,
      appointmentResponse: appointment.appointmentResponse,
      adminStatus: appointment.adminStatus,
      patientStatus: 'Deleted',
      requestDate: appointment.requestDate,
      // possibleTimes: appointment.possibleTimes,
      appointmentId: appointment.appointmentId,
      chosenDoctor: appointment.chosenDoctor,
      appointmentType: appointment.appointmentType,
      status: 'Deleted',
      userData: appointment.userData,
      cancelReason: appointment.cancelReason
    };
    return this.afs
      .collection('appointments')
      .doc(`${appointment.status}`)
      .collection('appointments')
      .doc<IAppointment>(`${appointment.appointmentId}`)
      .delete()
      .catch()
      .then(() => {
        this.statusType.next('Delete');
        this.afs
          .collection('appointments')
          .doc('Deleted')
          .collection('appointments')
          .add(deleteAppointmentData)
          .then(() => {
          });
      });
  }

  adminDeleteAppointment(appointment: IAppointment): Promise<void> {
    const deleteAppointmentData = {
      appointmentDate: appointment.appointmentDate,
      appointmentInformation: appointment.appointmentInformation,
      appointmentResponse: appointment.appointmentResponse,
      adminStatus: 'Deleted',
      patientStatus: appointment.patientStatus,
      requestDate: appointment.requestDate,
      // possibleTimes: appointment.possibleTimes,
      appointmentId: appointment.appointmentId,
      chosenDoctor: appointment.chosenDoctor,
      appointmentType: appointment.appointmentType,
      status: 'Deleted',
      userData: appointment.userData,
      cancelReason: appointment.cancelReason
    };

    if (appointment.status === 'Deleted') {
      return this.afs
        .collection('appointments')
        .doc(`${appointment.status}`)
        .collection('appointments')
        .doc<IAppointment>(`${appointment.appointmentId}`)
        .set(deleteAppointmentData, {merge: true});
    } else {
      return this.afs
        .collection('appointments')
        .doc(`${appointment.status}`)
        .collection('appointments')
        .doc<IAppointment>(`${appointment.appointmentId}`)
        .delete()
        .catch()
        .then(() => {
          this.afs
            .collection('appointments')
            .doc('Deleted')
            .collection('appointments')
            .add(deleteAppointmentData)
            .then(() => {
            });
        });
    }
  }


  // deleteAppointment(appointment: IAppointment): Promise<void> {
  //   return this.afs
  //     .collection<IAppointment>('appointments')
  //     .doc(`${appointment.status}`)
  //     .collection('appointments')
  //     .doc(`${appointment.appointmentId}`)
  //     .delete()
  //     .catch()
  //     .then(() => {
  //       this.statusType.next('Delete');
  //       // this.unselectSubject.next(null)
  //     });
  // }

  cancelSubscriptions() {
    this.fbSubs.forEach(sub => sub.unsubscribe());
  }
}
